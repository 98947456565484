import * as React from "react"
import ContentPageLayout from "../../../components/ContentPageLayout"
import { getLives } from "../../../api/content"
import { BiLeftArrowAlt } from "@react-icons/all-files/bi/BiLeftArrowAlt"
import { Link, navigate } from "gatsby"
import config, { firebaseInit } from "../../../config"
import AgoraRTC from "agora-rtc-sdk-ng" // Cambia a la nueva versión del SDK
import AgoraRTM from "agora-rtm-sdk"
import AgoraVideoContainer from "../../../components/AgoraVideoContainer"
import AgoraChatContainer from "../../../components/chat/AgoraChatContainer"
import SCButton from "../../../components/buttons/SCButton"
import firebase from "firebase"
import PremiumButton from "../../../components/buttons/PremiumButton"

const AgoraRTCclientId = "af246c8f653542249d12d30d4b7c1c34" // PROD?
const AgoraRTMclientId = "c9deaaaf66aa461eba102db6881929cc" // TEST
const AgoraRTMToken =
  "00670a7ce0fe8c74a50b29040d20aa92652IAA4G7sGqjvKoC1mcyGfOqI03OXXcMN6mGczbOwobxEKEbfv3IMAAAAAEABM3CgvJo7EYgEA6AMmjsRi"
const testing = false

const LivePage = ({ ...props }) => {
  const liveId = props.params.id
  const [live, setLive] = React.useState(null)
  const [userHasSuscription, setUserHasSuscription] = React.useState(false)
  const [isContentPremium, setIsContentPremium] = React.useState(true)
  const [isAddVideoId, setIsAddVideoId] = React.useState(null)
  const [volume, setVolume] = React.useState(70)
  const [channel, setChannel] = React.useState(null)
  const [messages, setMessages] = React.useState([])
  const [user, setUser] = React.useState(null)
  const [userId, setUserId] = React.useState(null)
  const [timeUse, setTimeUse] = React.useState(0)
  const [runTime, setRunTime] = React.useState(false)
  const [userToken, setUserToken] = React.useState(null)
  const [audioTrack, setAudioTrack] = React.useState(null) // Nueva variable de estado para la pista de audio

  let clientchat

  const profileUri = config.serverUri

  // Obtener la fecha y hora actual
  const currentDate = new Date()

  // Traer los datos del live
  React.useEffect(() => {
    getLives(liveId).then(data => {
      setLive(data)
      setIsContentPremium(data.tipoContenido)
    })
  }, [liveId])

  // Traer la suscripción del usuario
  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken(true).then(token => {
          setUserToken(token)
        })
        fetch(`${profileUri}/users/?email=${user.email}`)
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            setUserHasSuscription(data[0].suscription == "premium")
            console.log(data[0].suscription == "premium")
              setUserId(data[0].id)
              const connect = async () => {
                clientchat = AgoraRTM.createInstance(AgoraRTMclientId)
                await clientchat.login({
                  uid: String(data[0].id),
                  token: undefined,
                })
                const channel = clientchat.createChannel("main")
                await channel.join()

                channel.on("ChannelMessage", (message, memberId) => {
                  setMessages(prevMessages => [
                    ...prevMessages,
                    { uid: memberId, text: message.text },
                  ])
                })
                setChannel(channel)
              }
              connect()
            }
          })
      } else {
        setUserHasSuscription(false)
      }
    })
  }, [])

  // Manejar el stream de Agora
  React.useEffect(() => {
    if (!live) return

    const handleError = err => {
      console.log("Error: ", err)
    }

    const addVideoStream = elementId => {
      setIsAddVideoId(elementId)
    }

    // Migración a AgoraRTC NG
    const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" })

    const joinChannel = token => {
      client
        .join(AgoraRTCclientId, live.channel, token)
        .then(uid => {
          console.log("Joined channel:", uid)
        })
        .catch(handleError)
    }

    if (testing) {
      joinChannel(AgoraRTMToken)
    } else if (live?.token) {
      joinChannel(live.token)
    }

    client.on("user-published", (user, mediaType) => {
      client.subscribe(user, mediaType).then(() => {
        if (mediaType === "audio") {
          console.log(user.audioTrack)
          setAudioTrack(user.audioTrack)
          user.audioTrack.play()
        }
        if (mediaType === 'video') {
          const streamId = String(user.uid)
          addVideoStream(streamId)
          user.videoTrack.play(streamId)
        }
      }).catch(handleError)
    })

    client.on("user-unpublished", user => {
      const streamId = String(user.uid)
      setIsAddVideoId(null)
    })

    return () => {
      client.leave()
    }
  }, [live])

  const volumen = newValue => {
    setVolume(newValue)
  }

  const sendMessages = messages => {
    if (channel) {
      channel.sendMessage({ text: messages })
    }
  }

  const handlePlayLive = live => {
    const b2bTime = JSON.parse(localStorage.getItem("B2B_PLAY"))
    if (!b2bTime) {
      const data = { start: Date.now(), content: live, token: userToken }
      localStorage.setItem("B2B_PLAY", JSON.stringify(data))
    }
  }

  const saveContentView = () => {
    const data = { contenidoId: live.id, contenido: live.nombre }
    createContentReproduced(userToken, data).then(res => console.log(res))
  }

  const liveStartTime = new Date(live?.fechaInicio)
  const liveEndTime = new Date(live?.fechaFin)

  // Nueva lógica para permitir acceso 5 minutos antes
  const isWithinFiveMinutes = (liveStartTime - currentDate) <= 5 * 60 * 1000
  const isLiveUpcoming = liveStartTime > currentDate && !isWithinFiveMinutes

  return (
    <ContentPageLayout>
      {live && (
        <div className="pb-20">
          <div>
            <div
              className="flex relative"
              style={{ height: "240px", width: "100%" }}
            >
              <img
                className="absolute"
                // src={`${profileUri}/uploads/banner_superior_8acc5551bb.png`}
                src={`https://strapistorage.blob.core.windows.net/uploads/assets/Home_3_d7d3eabd55.jpg`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                alt={props.name}
              ></img>
              <div className="absolute flex p-5 h-full flex-col align-bottom">
                <h1 className="text-white ">Lives</h1>
                <p className="text-white">
                  Aquí podrás encontrar tu rutina ideal de acuerdo al grupo
                  muscular que quieras trabajar y la intensidad adecuada para tu
                  nivel.
                </p>
              </div>
            </div>
          </div>
          <div className="w-11/12 my-6 mx-8 flex items-center">
            <Link to={`/sections/entrenamiento`}>
              <BiLeftArrowAlt className="text-3xl" />
            </Link>
            <span className="pl-4">Entrenamiento / Planes / {live.nombre}</span>
          </div>
          <div className="w-10/12 mx-auto my-0">
            <div className="p-8">
              <h2 className="text-sc-titles">{live.nombre}</h2>
              <p>{live.objetivo}</p>
            </div>
            <div className="flex flex-col justify-center w-full md:flex-row md:p-8 md:justify-center">
              {isLiveUpcoming ? (
                <div className="w-full flex flex-col items-center justify-center p-10">
                  <p className="text-3xl">Este contenido estará disponible a las {liveStartTime.toLocaleTimeString()} del {liveStartTime.toLocaleDateString()}</p>
                </div>
              ) : (isContentPremium === "premium" && userHasSuscription !== true) ? (
                <div className="w-full flex flex-col items-center justify-center p-10">
                  <p className="text-3xl">
                    Se necesita ser usuario Shifter Premium para poder visualizar este contenido
                  </p>
                  <div className="w-72 mt-8 mx-auto">
                    <PremiumButton
                      onClick={() => navigate("/sections/entrenamiento")}
                    >
                      Hazte premium
                    </PremiumButton>
                  </div>
                </div>
              ) : (
                <>
                  {!isAddVideoId ? (
                    <>
                      <img
                        src={
                          `${profileUri}${live.portada?.url}` ||
                          "../../../images/entrenamiento.png"
                        }
                        style={{
                          minWidth: "300px",
                          height: "100%",
                          objectFit: "cover",
                          overflow: "hidden",
                        }}
                        alt={live.name}
                      ></img>
                      <div className="p-8">
                        <div className="mt-8">
                          <div className="p-8 flex items-center align-center justify-center">
                            <h4 className="text-center">
                              Por el momento el chat no está disponible
                            </h4>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <AgoraVideoContainer
                        vol={volume}
                        volumen={volumen}
                        isAddVideoId={isAddVideoId}
                        audioTrack={audioTrack}
                      />
                      <div className="p-0 md:pl-4 flex items-center md:w-1/2 align-center justify-center">
                        <AgoraChatContainer
                          uid={userId}
                          channel={channel}
                          sendMessage={sendMessages}
                        />
                      </div>
                      {() => {
                        setRunTime(true)
                        handlePlayLive(live)
                      }}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </ContentPageLayout>
  )
}

export default LivePage
